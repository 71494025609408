import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import mainPhoto from "../../assets/photos/mainPhoto1.png"
import mainPhotoMobile from "../../assets/photos/mainPhotoMobile.png"
import secondPhoto from "../../assets/photos/secondPhoto2.png"
import secondPhotoMobile from "../../assets/photos/secondPhotoMobile.png"
import thirdPhoto from "../../assets/photos/thirdPhoto.png"
import truck1 from "../../assets/photos/truck1.png"
import area from "../../assets/photos/area.png"
import area2 from "../../assets/photos/area2.png"


import {useTranslation} from "react-i18next";
import {Image} from "@nextui-org/react";
import {useTheme} from "next-themes";
import useMediaQuery from '@mui/material/useMediaQuery';
import {Slide} from "react-reveal";
import Carousel from "react-multi-carousel";
import ProjectShowcaseComponent from "../../components/ProjectShowcase/ProjectShowcaseComponent";
import TabInfoComponent from "../../components/TabInfo/TabInfoComponent";
import BackToTopComponent from "../../components/BackToTop/BackToTopComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";


const HomePage = () => {
    const {t} = useTranslation()
    const {theme, setTheme} = useTheme()
    const isMobile = useMediaQuery('(max-width: 640px)'); // Assuming 640px is your mobile breakpoint

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.

        }
    };

    const references = [
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },
        {
            name: "Mercedes",
            imgUrl: "https://img1.cgtrader.com/items/4060860/05ef4c642c/large/mercedes-benz-logo-3d-3d-model-05ef4c642c.jpg"
        },

    ];

    
    const tabsData = [
        {
            label: "Bölüm 1",
            imgUrl: truck1,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            label: "Bölüm 2",
            imgUrl: area,
            desc: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        },
        {
            label: "Bölüm 3",
            imgUrl: area2,
            desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
    ];
    
    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        autoplay: true,
        speed: 3000,
        arrows: false,
        autoplaySpeed: 4000,
    };
    
    const [loadingTimer,setLodingTimer] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLodingTimer(false)
        },3000)
    }, []);
    
    return (
        <div className={""}>
            <LoadingComponent loadingTimer={loadingTimer}/>

            <div className={""}>
                <Slider {...settings}>
                    <div className="relative">
                        <img src={isMobile ? mainPhotoMobile : mainPhoto} alt="Image 1"
                            className="w-full object-cover h-50 sm:h-48 md:h-96 lg:h-[40rem]"/>
                        {/*bg-gradient-to-r from-yellow-600/70 to-transparent*/}
                        {isMobile ? (
                            <div className="absolute inset-0 flex justify-start p-5 pt-[7rem]">
                                <p className="text-white font-extrabold italic text-md ">
                                    {t("mainIdea").split(' ').map((word, index) => (
                                        (index > 0 && index % 3 === 0) ? (
                                            <>
                                                <br/>{`${word} `}
                                            </>
                                        ) : (
                                            <>
                                                {`${word} `}
                                            </>
                                        )
                                    ))}
                                </p>
                            </div>
                        ) : (
                            <div className="absolute inset-0 flex justify-start p-10 pt-20">
                                <h2 className="text-white font-extrabold italic text-3xl md:text-3xl lg:text-6xl">
                                    {t("mainIdea").split(' ').map((word, index) => (
                                        (index > 0 && index % 3 === 0) ? (
                                            <>
                                                <br/>{`${word} `}
                                            </>
                                        ) : (
                                            `${word} `
                                        )
                                    ))}
                                </h2>
                            </div>
                        )}

                    </div>
                    <div>
                        <img src={isMobile ? secondPhotoMobile : secondPhoto} alt="Image 2"
                            className="w-full object-cover h-50 sm:h-48 md:h-96 lg:h-[40rem]"/>

                        {isMobile ? (
                            <div
                                className="absolute left-1/2 transform -translate-x-1/2 flex justify-start text-center top-[2rem]">
                                <h2 className="text-white font-extrabold italic text-xl">
                                    {t("mainIdea").split(' ').map((word, index) => (
                                        (index > 0 && index % 2 === 0) ? (
                                            <>
                                                <br/> {`${word} `}
                                            </>
                                        ) : (
                                            `${word} `
                                        )
                                    ))}
                                </h2>
                            </div>
                        ) : (
                            <div
                                className="absolute left-1/2 transform -translate-x-1/2  flex justify-center top-[2.5rem] text-center">
                                <h2 className="text-white font-extrabold italic text-3xl md:text-3xl lg:text-6xl">
                                    {t("mainIdea").split(' ').map((word, index) => (
                                        (index > 0 && index % 3 === 0) ? (
                                            <>
                                                <br/>{`${word} `}
                                            </>
                                        ) : (
                                            `${word} `
                                        )
                                    ))}
                                </h2>
                            </div>

                        )}
                    </div>
                    <div>
                        <img src={thirdPhoto} alt="Image 3"
                            className="w-full object-cover h-50 sm:h-64 md:h-96 lg:h-[40rem]"/>
                    </div>
                </Slider>
            </div>


            <div className={"container mx-auto mt-10 mb-20"}>
                <Slide bottom duration={1000}>
                    <div className={"about my-20 mx-6"} style={{background: "", color: ""}}>

                        <div className={""}>
                            <p
                                className={"sm:text-4xl text-2xl text-center mb-0 font-semibold"}>{t("about").toLocaleUpperCase()}
                            </p>
                        </div>


                        <div className={"w-full block sm:flex items-center"}>

                            <svg id="10015.io" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <clipPath id="blob">

                                        <path fill="#474bff"
                                            d="M406.5,290Q396,340,359.5,380.5Q323,421,271,405Q219,389,180.5,371Q142,353,76.5,330Q11,307,19.5,242.5Q28,178,83.5,151Q139,124,175.5,83Q212,42,274.5,34.5Q337,27,385,71.5Q433,116,425,178Q417,240,406.5,290Z"/>
                                    </clipPath>
                                </defs>
                                <image x="0" y="0" width="100%" height="100%" clipPath="url(#blob)"
                                    xlinkHref="https://img.piri.net/mnresize/712/-/piri/upload/3/2023/10/24/77b63f02-yilgz5he0nr97y0cklim.jpeg"
                                    preserveAspectRatio="xMidYMid slice"></image>
                            </svg>

                            <div className={"font-semibold text-xl mx-4"}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                took a
                                galley of type and scrambled it to make a type specimen book.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                took a
                                galley of type and scrambled it to make a type specimen book.
                                took a
                                galley of type and scrambled it to make a type specimen book.

                            </div>
                        </div>


                    </div>
                </Slide>

                <div className={"transportation"}>
                    <p
                        className={"sm:text-4xl text-2xl text-center mb-10 font-semibold"}>{t("globalTransportation").toLocaleUpperCase()}</p>


                        <TabInfoComponent tabs={tabsData} />
          
                </div>

                <div className={"projects mt-20"}>
                    <div className={"mb-5"}>
                        <p
                            className={"sm:text-4xl text-2xl text-center mb-0 font-semibold"}>{t("projects").toLocaleUpperCase()}
                        </p>
                    </div>

                    <div className={"projects-content-"}>
                        <Carousel
                            additionalTransfrom={0}
                            swipeable={false}
                            draggable={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            arrows={true}
                            autoPlay={true}
                            autoPlaySpeed={5000} // Otomatik oynatma hızını 5000ms yapın
                            keyBoardControl={true}
                            customTransition="all 2s ease-in-out" // Daha yavaş ve yumuşak bir geçiş efekti için değiştirilmiş
                            transitionDuration={2000} // Geçiş süresini 2000ms yapın
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            itemClass=""
                        >
                            <ProjectShowcaseComponent
                                title={"TPAO Gabar Petrol Taşıma Projesi"}
                                desc={"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."}
                                link={"https://google.com"}
                                img={truck1}/>

                            <ProjectShowcaseComponent
                                title={"TPAO Gabar Petrol Taşıma Projesi"}
                                desc={"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."}
                                link={"https://google.com"}
                                img={area}/>

                            <ProjectShowcaseComponent
                                title={"TPAO Gabar Petrol Taşıma Projesi"}
                                desc={"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."}
                                link={"https://google.com"}
                                img={area2}/>

                            <ProjectShowcaseComponent
                                title={"TPAO Gabar Petrol Taşıma Projesi"}
                                desc={"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."}
                                link={"https://google.com"}
                                img={"https://gomotive.com/wp-content/uploads/2022/08/What-is-the-most-common-mode-of-oil-transportation-Feature.jpg?w=1440&h=798&crop=1"}/>

                            <ProjectShowcaseComponent
                                title={"TPAO Gabar Petrol Taşıma Projesi"}
                                desc={"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."}
                                link={"https://google.com"}
                                img={"https://www.econonord.com/wp-content/uploads/2018/03/Solution-Transport-Petrole-Voie-Terrestre.jpg"}/>

                        </Carousel>
                    </div>
                </div>


                <div className={"references mt-16"}>
                    <div className={"mb-10"}>
                        <p
                            className={"sm:text-4xl text-2xl text-center mb-0 font-semibold"}>{t("references").toLocaleUpperCase()}
                        </p>
                    </div>

                    <div className="references w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                        {references.map((reference, index) => (
                            <div key={index} className="flex flex-col items-center px-8">
                                <Image
                                    src={reference.imgUrl}
                                    alt={reference.name}
                                    className="rounded-full"
                                    width={200}
                                    height={200}
                                />
                                <p className="font-semibold my-2 text-xl">{reference.name}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <BackToTopComponent/>
        </div>

    );
}

export default HomePage;