import React, {useState, useEffect} from 'react';
import {Card, CardHeader, CardBody, Image} from "@nextui-org/react";
import logo from "../../assets/logo.png";
import {useTranslation} from "react-i18next";

const FooterComponent = () => {
    const {t} = useTranslation()

    return(
        <div className={""}>
            

            <footer className="bg-white rounded-lg shadow dark:bg-black m-4 mb-0">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <a href="https://oiltrans.com.tr/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src={logo} className="h-20" alt="footerLogo" />
                            {/*<span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>*/}
                        </a>
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">{t("about")}</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">{t("about")}</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">{t("contact")}</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline">{t("contact")}</a>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://oiltrans.com.tr/" className="hover:underline">OilTrans™</a>. All Rights Reserved.</span>
                
                    
                </div>
            </footer>
            
        </div>
    )
}

export default FooterComponent;
