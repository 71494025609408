// dataService.js
import { db } from './firebaseService';
import { doc, getDoc } from "firebase/firestore";

export const getLanguages = async () => {
    try {
        // Create a reference to the document
        const docRef = doc(db, "langs", "cNxeAvBVwnuhkrFzDSOX");
        
        // Get the document
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            // Access the data within the document
            const data = docSnapshot.data();
            return Object.values(data); // Convert the data to an array of languages
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error getting document:", error);
        return [];
    }
};
