import React from "react";
import {
    Navbar,
    NavbarBrand,
    NavbarMenuToggle,
    NavbarMenuItem,
    NavbarMenu,
    NavbarContent,
    NavbarItem,
    Link,
    Image, Button,
} from "@nextui-org/react";
import logo from "../../assets/logo.png";
import TranslateComponent from "../Translate/TranslateComponent";
import {useTranslation} from "react-i18next";
import {useTheme} from "next-themes";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch"
export default function NavbarComponent() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const {t} = useTranslation();
    const { theme, setTheme } = useTheme()

    
    const handleThemeChange = () => {
        setTheme(theme === "light" ? "dark" : "light");
    };
    
    return (
        <Navbar
            className={"px-1 sm:px-5 py-3"}
            maxWidth={"full"}
            isBordered
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
        >
            <NavbarContent className="sm:hidden" justify="start">
                <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"}/>
            </NavbarContent>

            <NavbarContent className="sm:hidden" justify="centers">
                <NavbarBrand className={"cursor-pointer"} >
                    <Image onClick={() => window.location.href = "/"}  width={130} height={100} src={logo}/>
                </NavbarBrand>
            </NavbarContent>

            <Image onClick={() => window.location.href = "/"} className={"hidden sm:flex"} width={190} height={100} src={logo}/>
                
            <NavbarContent className="hidden sm:flex gap-8 font-semibold w-full" justify="center">
                <NavbarItem>
                    <Link color="foreground" href="/about">
                        {t("about")}
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" href="/projects">
                        {t("projects")}
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" href="/references">
                        {t("references")}

                    </Link>
                </NavbarItem>
                
                <NavbarItem>
                    <Link color="foreground" href="/contact">
                        {t("contact")}
                    </Link>
                </NavbarItem>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex w-full" justify={"end"}>
                <NavbarItem className="w-full hidden sm:flex">
                    <TranslateComponent/>
                    <div className={"mx-5"}>
                        <ThemeSwitch 
                            isSelected={theme === "light"} 
                            onChange={handleThemeChange} 
                            />
                    </div>
                </NavbarItem>
            </NavbarContent>
            

            {/*mobile*/}
            <NavbarMenu>

         


                <NavbarMenuItem className={"mt-10"}>
                    <Link color="foreground" href="/about">
                        {t("about")}
                    </Link>
                </NavbarMenuItem>


                <NavbarMenuItem className={"mt-6"}>
                    <Link color="foreground" href="/projects">
                        {t("projects")}
                    </Link>
                </NavbarMenuItem>


                <NavbarMenuItem className={"mt-6"} >
                    <Link color="foreground" href="/references">
                        {t("references")}
                    </Link>
                </NavbarMenuItem>
                
                <NavbarMenuItem className={"mt-6"} >
                    <Link color="foreground" href="/contact">
                        {t("contact")}
                    </Link>
                </NavbarMenuItem>
                
                <NavbarMenuItem className={"mt-6"}>
                  <TranslateComponent/>
                  </NavbarMenuItem>
            </NavbarMenu>
            <NavbarContent className="flex sm:hidden " justify="end">

                <ThemeSwitch 
                    isSelected={theme === "light"} 
                    onChange={handleThemeChange} 
                    />
            </NavbarContent>
        </Navbar>
    );
}
