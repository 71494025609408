import React, {useState, useEffect} from 'react';
import {Card, CardHeader, CardBody, Image} from "@nextui-org/react";

const ProjectShowcaseComponent = (props) => {
    const {img,title,desc,link} = props;
    
    
    return(
        <div>
            <Card className="py-0 m-2 mx-5">
                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <Image
                        alt="Card background"
                        className="object-cover rounded-xl h-64"
                        src={img}
                        width={500}
                        />
                </CardHeader>
                <CardBody className="overflow-visible py-3 mx-2">
                   
                    
                    
                    <h4 className="font-bold text-large">{title}</h4>
                    <small className="text-default-500">{desc}</small>
                    {/*<p className="text-tiny uppercase font-bold">xxx</p>*/}
                </CardBody>
            </Card>
        </div>
    )
}

export default ProjectShowcaseComponent;
