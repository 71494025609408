import React, { useState } from 'react';
import {Chip} from "@nextui-org/react";

const ProjectComponent = ({ images, title, year, desc }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="relative w-full mb-32">
            <p className="font-bold text-5xl mt-10 mb-2">{title}</p>
            <Chip className={"mt-1 font-semibold text-xl mb-6"} color="success" size={"lg"} variant="dot">{year}</Chip>

            <div className="flex flex-col md:flex-row md:items-start md:gap-6">
                <div className="relative h-56 overflow-hidden rounded-lg md:h-96 md:flex-1">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`absolute inset-0 transition-opacity duration-700 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
                        >
                            <img src={image} className="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt={`Gallery image ${index + 1}`} />
                        </div>
                    ))}
                </div>
                
                <div className="md:hidden flex justify-center items-center py-4">
                                    <button onClick={prevImage} className="flex justify-center items-center me-4 h-full cursor-pointer group focus:outline-none">
                                        <span className="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                                            <svg className="rtl:rotate-180 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                                            </svg>
                                            <span className="sr-only">Previous</span>
                                        </span>
                                    </button>
                                    <button onClick={nextImage} className="flex justify-center items-center h-full cursor-pointer group focus:outline-none">
                                        <span className="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                                            <svg className="rtl:rotate-180 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                            </svg>
                                            <span className="sr-only">Next</span>
                                        </span>
                                    </button>
                                </div>
                
                                <div className="md:flex-1 my-4 text-left">
                                    <p className="font-semibold text-xl">{desc}</p>
                                </div>
                            </div>
                
                            <div className="hidden md:flex justify-center items-center pt-4">
                                <button onClick={prevImage} className="flex justify-center items-center me-4 h-full cursor-pointer group focus:outline-none">
                                    <span className="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                                        <svg className="rtl:rotate-180 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                                        </svg>
                                        <span className="sr-only">Previous</span>
                                    </span>
                                </button>
                                <button onClick={nextImage} className="flex justify-center items-center h-full cursor-pointer group focus:outline-none">
                                    <span className="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                                        <svg className="rtl:rotate-180 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                        </svg>
                                        <span className="sr-only">Next</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    );
                };
                
                export default ProjectComponent;
                