import React from "react";
import {Tabs, Tab, Card, CardBody, Image} from "@nextui-org/react";

const TabInfoComponent = ({ tabs }) => {
    return (
        <div className={"mx-5 sm:mx-0"}>
            <Tabs size={"sm"} color={"warning"} aria-label="Options">
                {tabs.map((tab, index) => (
                    <Tab key={index} title={tab.label}>
                        <Card>
                            <CardBody>
                                <div className={"grid grid-cols-12 gap-3 items-center mb-4"}>
                    
                                    <div className={"col-span-12 flex items-center justify-center sm:col-span-5"}>
                                        <Image className={"md:w-auto sm:w-24 lg:w-auto"} src={tab.imgUrl} alt=""/>
                                    </div>
                                
                                    <div className={"col-span-12 sm:col-span-7 mx-5 mb-12 sm:mb-2"}>
                                        <p className={"text-left text-md sm:text-xl"}>
                                          {tab.desc}
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>  
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}

export default TabInfoComponent;
