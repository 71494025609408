import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Image } from "@nextui-org/react";
import { getLanguages } from '../../services/dataService';
import i18next from 'i18next';
import { LanguageContext } from "../../context/LanguageContext";

const TranslateComponent = () => {
    const [languages, setLanguages] = useState([]);
    const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);

    useEffect(() => {
        const fetchLanguages = async () => {
            const langsArray = await getLanguages();
            setLanguages(langsArray);
            if (langsArray.length > 0) {
                const storedLanguageCode = localStorage.getItem('selectedLanguage');
                const defaultLanguage = langsArray.find(lang => lang.code === storedLanguageCode) || langsArray.find(lang => lang.code === 'tr') || langsArray[0];
                setSelectedLanguage(defaultLanguage);
                i18next.changeLanguage(defaultLanguage.code); // Set default language
            }
        };

        fetchLanguages();
    }, [setSelectedLanguage]);

    const handleLanguageChange = (code) => {
        const newLanguage = languages.find(lang => lang.code === code);
        setSelectedLanguage(newLanguage);
        i18next.changeLanguage(code); // Change language
        localStorage.setItem('selectedLanguage', code); // Save selected language to local storage
    };

    return (
        <div className="flex justify-start sm:justify-end sm:w-[150px] w-full">
            {selectedLanguage && (
                <Dropdown>
                    <DropdownTrigger>
                        <div className={"flex gap-2 items-center cursor-pointer"}>
                            <Image width={40} src={selectedLanguage.image} alt={selectedLanguage.name} />
                            {selectedLanguage.name}
                        </div>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Select Language">
                        {languages.map(lang => (
                            <DropdownItem
                                key={lang.code}
                                onClick={() => handleLanguageChange(lang.code)}
                                startContent={<Image width={40} src={lang.image} alt={lang.name} />}
                            >
                                {lang.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
        </div>
    );
};

export default TranslateComponent;
