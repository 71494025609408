
import {Card, CardHeader, CardBody, CardFooter, Progress, Divider, Link, Image} from "@nextui-org/react";
import {useTranslation} from "react-i18next";

const ReferencesPage = () => {
    const {t} = useTranslation()

    
    
    const references = [
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."
        },
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },
        {
            name: "Mercedes",
            imgUrl: "https://img1.cgtrader.com/items/4060860/05ef4c642c/large/mercedes-benz-logo-3d-3d-model-05ef4c642c.jpg",
            desc:"Lore ipsum dolor sit collor amet means to render carousel on server-side. means to render carousel on server-side."

        },

    ];

    
    return(
        <div>
            

            <section className="bg-white pb-6 dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">{t("references")}</h1>
                <div className={"text-center flex justify-center my-5"}>
                    <Progress
                        size="sm"
                        color={"danger"}
                        isIndeterminate
                        aria-label="Loading..."
                        className="max-w-md"
                    />
                </div>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
                        Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
                        Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
                    </p>
                    <Divider className="my-4" />

                    
                    
                
                </div>
                <div className={"mt-10 pb-20 mx-5 sm:mx-auto sm:container"}>
                    
                    <div className="references w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {references.map((reference, index) => (
                            <div key={index} className="flex flex-row items-center px-8">
                               
                                <Card className="max-w-[400px] p-1">
                                    <CardHeader className="flex gap-3">
                                        <Image
                                            alt="nextui logo"
                                            height={55}
                                            radius="lg"
                                            src={reference.imgUrl}
                                            width={55}
                                        />
                                        <div className="flex flex-col">
                                            <p className="text-2xl font-semibold">{reference.name}</p>
                                        </div>
                                    </CardHeader>
                                    <Divider/>
                                    <CardBody>
                                        <p>{reference.desc}</p>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </div>
                        
                    </div>
                <div className="bg-gradient-to-b from-red-200 to-transparent dark:from-red-950 w-full dark:h-[40rem] absolute top-0 left-0 z-0"></div>
            </section>
            
            
        </div>
        )
    
}

export default ReferencesPage;