
import {Card, CardHeader, CardBody, CardFooter, Progress, Divider, Link, Image} from "@nextui-org/react";
import {useTranslation} from "react-i18next";

const ContactPage = () => {
    const {t} = useTranslation()

    
    return(
        <div>
            

            <section className="bg-white pb-6 dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">{t("contact")}</h1>
                <div className={"text-center flex justify-center my-5"}>
                    <Progress
                        size="sm"
                        color={"warning"}
                        isIndeterminate
                        aria-label="Loading..."
                        className="max-w-md"
                    />
                </div>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
                        Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
                        Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
                    </p>
                    <Divider className="my-4" />

                    
                    
                
                </div>
                <div className={"mt-20 pb-20 mx-5 sm:mx-auto sm:container"}>
                    <div className={"grid grid-cols-12 items-center"}>
                        <div className={"col-span-12 sm:col-span-5"}>
                            <iframe className={"border-none w-full rounded-xl"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d195884.3004314574!2d32.59795774239392!3d39.90352329781132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d347d520732db1%3A0xbdc57b0c0842b8d!2sAnkara!5e0!3m2!1str!2str!4v1719648353224!5m2!1str!2str" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        
                        <div className={"col-span-12 sm:col-span-7 mx-10"}>
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-20 sm:mt-0">
                                <div className="col-span-1">
                                    <Card className="py-4">
                                        <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                            <i className="fa-solid text-primary fa-phone-volume text-center" style={{fontSize:"8rem"}}></i>
                                        </CardHeader>
                                        <CardBody className="overflow-visible py-2 text-center mt-5">
                                            <p className="text-tiny uppercase font-bold">Telefon</p>
                                            <small className="text-default-500">12 Saat Aktif</small>
                                            <h4 className="font-bold text-large">+90 534 123 45 67</h4>
                                        </CardBody>
                                    </Card>
                                </div>
                                
                                <div className="col-span-1">
                                    <Card className="py-4">
                                        <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                            <i className="fa-solid fa-envelope text-primary text-center" style={{fontSize:"8rem"}}></i>
                                        </CardHeader>
                                        <CardBody className="overflow-visible py-2 text-center mt-5">
                                            <p className="text-tiny uppercase font-bold">Mail</p>
                                            <small className="text-default-500">12 Saat Aktif</small>
                                            <h4 className="font-bold text-large">mail.oiltrans@gmail.com</h4>
                                        </CardBody>
                                    </Card>
                                </div>
                                
                                <div className="col-span-1">
                                    <Card className="py-4">
                                        <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                            <i className="fa-solid fa-address-card text-primary text-center" style={{fontSize:"8rem"}}></i>
                                        </CardHeader>
                                        <CardBody className="overflow-visible py-2 text-center mt-5">
                                            <p className="text-tiny uppercase font-bold">Bizden Biri</p>
                                            <small className="text-default-500">13:00 - 18:00</small>
                                            <h4 className="font-bold text-large">Kişi Kartı</h4>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                <div className="bg-gradient-to-b from-orange-200 to-transparent dark:from-orange-950 w-full dark:h-[40rem] absolute top-0 left-0 z-0"></div>
            </section>
            
            
        </div>
        )
    
}

export default ContactPage;