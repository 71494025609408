import React, {useState, useEffect} from 'react';
import {Spinner} from "@nextui-org/react";
import {useTheme} from "next-themes";
import {useTranslation} from "react-i18next";

const LoadingComponent = (props) => {
    const {loadingTimer} = props;
    const {t} = useTranslation();
    const {theme, setTheme} = useTheme()

//    const [loading, setLoading] = useState(true);

//    useEffect(() => {
//        const images = document.querySelectorAll('img');
//        let count = 0;
//
//        const handleLoading = () => {
//            count++;
//            if (count === images.length) {
//                setLoading(false);
//            }
//        };
//
//        images.forEach(image => {
//            if (image.complete) {
//                handleLoading();
//            } else {
//                image.addEventListener('load', handleLoading);
//            }
//        });
//
//        return () => {
//            images.forEach(image => {
//                image.removeEventListener('load', handleLoading);
//            });
//        };
//    }, []);

//    useEffect(() => {
//        setTimeout(() => {
//            setLoading(false)
//        },4500)
//    }, [])
    return loadingTimer ? (
        <div
            className={`fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 ${theme === "light" ? "bg-white" : "bg-black"}`}>
            <Spinner label={`${t("loading")}...`} color="warning" size={"lg"} labelColor="warning"/>
            </div>
    ) : null;
};

export default LoadingComponent;
