import {Progress,Divider} from "@nextui-org/react";
import ProjectComponent from "../../components/Project/ProjectComponent";
import truck1 from "../../assets/photos/truck1.png"
import area from "../../assets/photos/area.png"
import area2 from "../../assets/photos/area2.png"
import {useTranslation} from "react-i18next";


const ProjectsPage = () => {
    const {t} = useTranslation()
    
    const images = [
   truck1,area,area2
    ];    
    
    return(
        <div>
            

            <section className="bg-white pb-6 dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">{t("projects")}</h1>
                <div className={"text-center flex justify-center my-5"}>
                    <Progress
                        size="sm"
                        color={"success"}
                        isIndeterminate
                        aria-label="Loading..."
                        className="max-w-md"
                    />
                </div>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
                        Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
                        Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
                    </p>
                    <Divider className="my-4" />

                    
                    
                 
                </div>
                <div className={"pt-20 mx-10 sm:mx-auto sm:container"}>
                    <ProjectComponent
                        title={"TPAO Gabar Petrol Projesi"}
                        year={"2024 / 2025"}
                        images={images}
                        desc="
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a
                        galley of type and scrambled it to make a type specimen book.
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a
                        galley of type and scrambled it to make a type specimen book.
                        took a
                        galley of type and scrambled it to make a type specimen book.
                        "
                    />
                    
                    
                    <ProjectComponent
                        title={"2.TPAO Gabar Petrol Projesi"}
                        images={images}
                        year={"2024 / 2025"}
                        desc="
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a
                        galley of type and scrambled it to make a type specimen book.
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a
                        galley of type and scrambled it to make a type specimen book.
                        took a
                        galley of type and scrambled it to make a type specimen book.
                        "
                        />
                        
                        
                    </div>
                <div className="bg-gradient-to-b from-green-200 to-transparent dark:from-green-900 w-full dark:h-[40rem] absolute top-0 left-0 z-0"></div>
            </section>
            
            
        </div>
        )
    
}

export default ProjectsPage;