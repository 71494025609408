import React, {Suspense, useContext, useEffect, useState} from 'react';
import NavbarComponent from './components/Navbar/NavbarComponent';
import HomePage from "./pages/Home/HomePage"
import {LanguageContext} from "./context/LanguageContext";
import LoadingComponent from "./components/Loading/LoadingComponent";
import FooterComponent from "./components/Footer/FooterComponent";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from "./pages/About/AboutPage";
import ProjectsPage from "./pages/Projects/ProjectsPage";
import ReferencesPage from "./pages/References/ReferencesPage";
import ContactPage from "./pages/Contact/ContactPage";

function App() {

  const { selectedLanguage } = useContext(LanguageContext);


  return (
    <div className="">
      <NavbarComponent />
      {/*<LoadingComponent />*/}

      <Router basename={"/"}>
        <Suspense >
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/references" element={<ReferencesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<>Not Found</>} />
          </Routes>
        </Suspense>
      </Router>

      <FooterComponent/>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ed8100" fillOpacity="0.9" d="M0,192L80,176C160,160,320,128,480,138.7C640,149,800,203,960,197.3C1120,192,1280,128,1360,96L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

    </div>
  );
}

export default App;
