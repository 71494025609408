// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA0-5KXUHkzwDt8qSO0LxpqAYJzeNhDTao",
    authDomain: "oiltrans-2a8c1.firebaseapp.com",
    projectId: "oiltrans-2a8c1",
    storageBucket: "oiltrans-2a8c1.appspot.com",
    messagingSenderId: "588240699734",
    appId: "1:588240699734:web:5840831000a5c036ded6f7",
    measurementId: "G-84P0REN4EZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get Firestore database reference
const db = getFirestore(app);

export { db };
